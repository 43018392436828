<template>
  <div id="loginPage" class="min-h-screen bg-white flex">
    <!-- Left Side -->
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-24 xl:px-24 leftLoginDiv">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div class="w-full text-center border-red">
          <img class="h-12" src="@/assets/images/logo-full.png" alt="Growlytics Login" />
        </div>

        <div class="mt-6">
          <!-- Error Message -->
          <div v-if="errorMsg" class="errorLabel bg-red-600 text-white p-2 rounded">
            {{ errorMsg }}
          </div>

          <!-- Form -->
          <div class="mt-6">
            <!-- Show Login Page -->
            <template v-if="!isSuccessPage">
              <el-form ref="loginForm" label-position="top" label-width="100px" :model="loginForm" :rules="formRules">
                <el-form-item label="Email address" prop="email">
                  <el-input id="email" name="email" type="email" autocomplete="email" v-model="loginForm.email" required></el-input>
                </el-form-item>
                <el-form-item label="Password" prop="password">
                  <el-input id="passwordInput" type="password" v-model="loginForm.password" required></el-input>
                </el-form-item>
              </el-form>

              <button @click="onLoginSubmit" class="w-full mt-8 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">Sign in</button>
            </template>

            <template v-else>
              <div class="text-gray-600 mb-3">Select Organization To Sync With Shopify</div>
              <ul role="list" class="divide-y divide-gray-200 border border-gray-400 rounded-md">
                <li v-for="org in organizationList" :key="org.id" class="py-3 text-sm px-3" style="cursor: pointer" @click="onOrganizationSelect(org)">
                  {{ org.name }}
                </li>
              </ul>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginComponent from './shopifyConnectLoginComponent';
export default LoginComponent;
</script>

<style lang="scss" src="./shopifyConnectLogin.scss"></style>
