import authService from '../../../services/auth';

export default {
  name: 'login',
  data() {
    return {
      errorMsg: '',
      loginProcessing: false,

      loginForm: null,
      formRules: null,

      isSuccessPage: false,
      token: null,
      organizationList: []
    };
  },
  computed: {},
  methods: {
    async onLoginSubmit() {
      try {
        this.errorMsg = '';

        // Validate Form
        let isValidData = await this.validateForm(this.$refs.loginForm);
        if (!isValidData) return;

        this.loginProcessing = true;

        let result = await authService.shopifyConnectLogin(this.loginForm);

        if (result.data.status == 401 || result.data.status == 403) {
          this.errorMsg = result.data.message;
        } else {
          // // Close Popup, Pass data to parent.
          // console.log('data', result.data);
          // window.opener.growlyticsLoginSuccess();

          this.organizationList = result.data.data.orgs;
          this.token = result.data.data.token;
          this.isSuccessPage = true;
        }

        this.loginProcessing = false;
      } catch (err) {
        this.loginProcessing = false;
        this.errorMsg = `Something's wrong. Pleasae contact our support team.`;
        console.error(err);
      }
    },

    async onOrganizationSelect(organization) {
      let obj = {
        eventName: 'Growlytics Login Success',
        id: organization.id + '-' + organization.pid,
        apikey: organization.api_key,
        name: organization.name
      };
      if (window.opener) {
        console.log('calling parent function...', obj);
        window.opener.postMessage(obj, window);
        window.opener.growlyticsLoginSuccess(obj);
        self.close();
      }
    }
  },

  created() {
    // Read form and rules from mixin
    this.loginForm = this.getLoginForm();
    this.formRules = this.getLoginFormRules();
  }
};
